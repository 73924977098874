@import '_vars.scss';

.drive-create-password {
	position: relative;
	display: block;
	margin-top: 20px;
	margin-bottom: 20px;
}

.drive-header {
	h2 {
		display: flex;
		flex-grow: 1;
	}
}

.drive-header-user-name {
	margin-left: 5px;
}

.drive-folders-toolbar {
	padding-left: 20px;
	padding-bottom: 10px;
}

.drive-folders-container {
	display: flex;
	align-items: flex-start;

	.file-button {
		align-items: flex-start;
	}
	.file-icon {
		svg {
			width: 25px;
			height: auto;
		}
		margin-right: 5px;
	}
	.file-size {
		display: block;
		text-align: left;
		text-transform: none;
	}

	.drive-folder-global {
		width: 100%;
		padding-top: 10px;
		padding-bottom: 10px;
	}

	.drive-folder-appointment-header {
		align-items: center;
		.drive-folder-appointment-header-total {
			display: flex;
			align-items: center;
		}
	}
}

.drive-files-uploader-file {
	.error {
		color: red;
	}
	display: flex;
	align-items: center;
	.drive-files-uploader-file-name {
		flex-grow: 1;
	}
	.drive-files-uploader-file-size {
		margin-left: 10px;
		margin-right: 10px;
	}
}

.circular-button-loading {
	position: relative;
	.circular {
		position: absolute;
		top: 50%;
		left: 50%;
		margin-left: -15px;
		margin-top: -15px;
	}
}

.drive-file-delete-message {
	span:nth-child(2) {
		white-space: nowrap;
	}
}

.drive-editor-template-chooser {
	button {
		&>span:first-child {
			display: block;
		}
		.icon {
			display: block;
			svg {
				width: 50px;
				height: auto;
			}
		}
	}
}

.drive-editor-playground {
	height: 65vh;
	div[class*='RichTextEditor__editor___'] {
		height: calc(100% - 51px);
		&>div {
			height: 100%;
		}
	}
}

.drive-free-space {
	width: 40%;
}


/* Tablette portrait */
@media screen and (max-width: 768px) {
	.drive-header {
		overflow-x: hidden;
		h6 {
			flex-wrap: wrap;
			.modale-drive-title-name {
				width: 100%;
			}
		}
		&>button {
			position: absolute;
			right: 0px;
			top: 0px;
		}
	}

	.drive-folders-container {
		flex-direction: column;
		.drive-folder-global, .drive-folders-appointments {
			width: 100%;
			min-width: 100%;
			max-width: 100%;
		}
	}
}
