@import '_vars.scss';

.website_header {
	height: 60px;
}

.website_topbar {
	padding-top: 20px;
	padding-bottom: 20px;
}

.website_form {
	&.site-title {
		position: relative;
		margin-top: 0px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		h1, h2 {
			text-align: center;
			margin: 0px;
			padding: 0px;
		}
	}
	margin-top: 40px;
	width: 760px;
	margin-left: auto;
	margin-right: auto;
	.message_content {
		min-height: 68px;
	}
}

.website_section {
	margin-bottom: 40px;
	position: relative;
	.website_actions {
		position: absolute;
		top: 10px;
		right: 5px;
		z-index: 10;
	}
	&.website_section--inactive {
		.website_card {
			h4 {
				color: $grey-main !important
			}
			.avatar--primary {
				background-color: $grey-main !important;
			}
		}
		.website_content {
			opacity: 0.3;
		}
	}
}

span.contact-label {
	font-weight: 500;
	width: 100px;
	display: inline-block;
}

span.schedule-day {
	font-weight: 500;
	width: 80px;
	display: inline-block;
}

.all_themes {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	.theme-choice {
		background: none;
		border: none;
		margin: 0px;
		padding: 0px;
		width: calc((100% - 20px) / 3);
		margin-top: 5px;
		margin-bottom: 5px;
		box-shadow: 0 1px 3px 0 rgba(140 ,140 ,140, 0.2), 1px 1px 4px 0 rgba(140, 140, 140, 0.3);
		outline: none;
		cursor: pointer;
		transition: box-shadow 0.2s ease-out, border 0.2s ease-out;
		border: 0px solid $success-main;
		&:hover {
			box-shadow: 0 2px 5px 0 rgba(140 ,140 ,140, 0.2), 5px 5px 12px 0 rgba(140, 140, 140, 0.3);
		}
		&.active {
			border: 5px solid $success-main;
		}
		.image {
			position: relative;
			img {
				width: 100%;
				height: auto;
			}
		}
		.name {
			display: block;
			padding: 10px;
		}
	}
}

/* Small desktop devices */
@media screen and (max-width: 1279px) {

}

/* Tablette Paysage */
@media screen and (max-width: 1024px) {

}

/* Tablette portrait */
@media screen and (max-width: 768px) {
	.website_form {
		width: 100%;
		&.site-title {
			padding-left: 20px;
			padding-right: 20px;
			.website_actions {
				top: -10px;
			}
		}
	}
	.website_section {
		margin-bottom: 20px;
	}
}

/* Mobile */
@media screen and (max-width: 640px) {
	.website_content {
		flex-direction: column;
		.direction_map {
			height: 200px;
		}
	}
}
