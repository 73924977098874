@import '_vars.scss';

.calendar-container {
    display: flex;
    height: 100%;
    flex-direction: column;
    .calendar {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        border: 1px solid #ddd;
        .agenda {
            width: 100%;
            height: 100%;
            position: relative;
            border-top: 2px solid #ddd;
            & > div > div:last-child {
                z-index: 120;
                width: 8px !important;
                & > div {
                    transition: background-color 0.2s linear;
                    &:hover {
                        background-color: rgba(0, 0, 0, 0.7) !important;
                    }
                }
            }
        }
        .agenda--header {
            height: 90px;
            min-height: 90px;
            max-height: 90px;
            display: flex;
            .header--gutter {
                min-width: 34px;
                width: 34px;
                max-width: 34px;
                background-color: #fff;
            }
            .header--column {
                flex-grow: 1;
                height: 100%;
                &.days--5 {
                    min-width: calc((100% - 34px) / 5);
                    max-width: calc((100% - 34px) / 5);
                }
                &.days--6 {
                    min-width: calc((100% - 34px) / 6);
                    max-width: calc((100% - 34px) / 6);
                }
                &.days--7 {
                    min-width: calc((100% - 34px) / 7);
                    max-width: calc((100% - 34px) / 7);
                }
            }
            .header_day-week {
                border-left: 1px solid #ddd;
            }
        }
        .column--step {
            position: absolute;
            width: 100%;
        }

        .agenda--content {
            display: flex;
        }

        .agenda--column {
            flex-grow: 1;
            position: relative;
            border-left: 1px solid #ddd;
            &.agenda--gutter {
                min-width: 34px;
                width: 34px;
                max-width: 34px;
                background-color: #fff;
                border-left: none;
            }
        }
    }

    .gutter--step {
        font-size: 10px;
        font-weight: 300;
        position: relative;
        color: #9e9e9e;
        text-align: center;
        transform: translateY(-50%);
    }
}

/* Mobile */
@media screen and (max-width: 640px) {
    .calendar-container {
        .calendar {
            .agenda--header {
                .header--column:not(.header--gutter) {
                    max-width: calc(100% - 34px) !important;
                    min-width: calc(100% - 34px) !important;
                }
            }
        }
    }
}
