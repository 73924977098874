.website--palette--blackwhite {
    color: #353535;
    background-color: #f5f5f5;

    h1, h2, h3, h4, h5, h6 {
        color: #353535;
    }
    .website_header {
        background-color: #353535;
    }

    .website_topbar {
        background-color: #ffffff;
    }
    .card {
        .card_title {
            .ico {
                color: #a9a9a9;
            }
        }
    }

    .separator_line {
        border-color: #a9a9a9;
    }

    a {
    	color: #353535;
    	&.secondary {
    		color: #353535;
    	}
    }
}
