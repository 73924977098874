@import '_vars.scss';

.planning_calendar {
	padding: 20px;
	padding-top: 5px;
	padding-bottom: 0px;
	height: calc(100vh - 64px);
}

.event_popover {
	margin-left: 5px;
	margin-right: 5px;
}

.event_popover_content {
	min-width: 400px;
	max-width: 600px;
	.event_popover_header {
		padding: 0px;
		padding-bottom: 50px;
		background-color: $primary-main;
		color: $primary-contrast;
		h1, h2, h3, h4, h5, h6 {
			color: $primary-contrast;
		}
		.event_popover_actions {
			padding-top: 5px;
			padding-bottom: 10px;
			display: flex;
			justify-content: flex-end;
			button {
				margin-right: 5px;
				color: #fff;
			}
		}
		.event_popover_title {
			padding-left: 20px;
			padding-right: 20px;
			a {
				color: inherit;
			}
		}
	}
	.event_floating-buttons {
		position: relative;
	}
	.event_edit {
		position: absolute;
		left: 20px;
		transform: translateY(-50%);
	}
	.event_join-visio {
		position: absolute;
		right: 20px;
		transform: translateY(-50%);
	}
	.event_create-payment {
		position: absolute;
		right: 90px;
		transform: translateY(-50%);
	}
	.event_popover_body {
		padding: 20px;
		padding-top: 50px;
	}

	.event_popover_confirm {
		padding: 20px;
		padding-top: 0px;
		text-align: center;
	}

	.event_popover_pvpp {
		padding: 20px;
		padding-top: 10px;
		padding-bottom: 10px;
		border-top: 1px solid #ddd;
	}

	&.event_popover_content--past {
		.event_popover_body {
			padding-top: 20px;
		}
		.event_popover_header {
			padding-bottom: 20px;
		}
	}
}


.high--contrast {
	.time-slot {
		&.slot--is-active {
			&:hover {
				background-color: $primary-contrast-high;
			}
		}
		&.slot--is-non-bookable {
			background-color: $grey-contrast-high;
		}
		&.slot--is-bookable {
			background-color: $success-contrast-high;
		}
		&.slot--is-blocked-by-rule {
			background-color: $secondary-contrast-high;
		}
		&.slot--is-past {
			background-color: $grey-contrast-high !important;
		}
		&.slot--is-closed-by-day {
			background-color: $error-contrast-high !important;
		}
	}
}


.time-slot {
	border-bottom: 1px solid #ddd;
	z-index: 1;
	font-size: 11px;
	border-left: 1px solid #ddd;
	.time-slot-handler {
		height: 100% !important;
		top: 0px !important;
		bottom: 0px !important;
		cursor: pointer !important;
	}
	.rbc-label {
		font-size: 10px;
		font-weight: 300;
		position: relative;
		background-color: #fff;
		top: -12px;
		color: $grey-main;
	}
	&.slot--is-active {
		cursor: pointer;
		z-index: 5;
		&:hover {
			background-color: $primary-contrast;
		}
	}
	&.slot--is-bookable {
		background-color: $success-contrast;
	}
	&.slot--is-blocked-by-rule {
		background-color: $secondary-contrast;
	}
	&.slot--is-non-bookable {
		background-color: $grey-contrast;
	}
	&.slot--is-closed-by-day {
		background-color: $error-contrast !important;
	}
	&.slot--is-past {
		background-color: $grey-contrast !important;
	}
	&.--resizing {
		color: #fff;
		background-color: rgba(0,0,0,0.6) !important;
		z-index: 15 !important;
	}
}

.header_day-week {
	padding: 10px;
	position: relative;
	color: $grey-main;
	height: 100%;
	h1, h2, h3, p {
		color: $grey-main;
	}
	p {
		font-weight: 300;
		text-transform: uppercase;
	}
	&.header_day--close {
		background-color: $error-contrast;
	}
	&.header_day--today {
		background-color: $primary-contrast;
	}
	.header_day_summary {
		position: absolute;
		right: 15px;
		bottom: 10px;
	}
	.header_day_actions {
		position: absolute;
		top: -5px;
		right: -5px;
	}
}
.rbc-allday-cell {
	display: none;
}

.planning_toolbar_picker {
	display: none;
}

/* Event */
.event_tooltip {
	.caption {
		color: $grey-contrast !important;
		svg {
			font-size: 16px;
		}
		&.title {
			svg {
				font-size: 24px;
			}
		}
		.label {
			text-decoration: underline;
			position: relative;
			&:after {
				content: ' :';
				margin-right: 5px;
			}
		}
	}
}
.event {
	z-index: 12;
	transition: opacity 0.2s linear, box-shadow 0.2s linear, background-color 0.2s linear;
	border: 1px solid #fff;
	h1, h2, h3, h4, h5, h6, p {
		color: #fff;
	}
	&.event--resizing {
		z-index: 45 !important;
	}
	&.event--dragging {
		z-index: 45 !important;
	}
	.event_body {
		padding: 1px;
		padding-left: 5px;
		padding-right: 3px;
		position: relative;
		.event_body_name {
			width: calc(100% - 30px);
			padding-right: 5px;
			svg {
				font-size: 18px;
				color: #fff;
			}
			p {
				margin: 0px;
				padding: 0px;
				flex-grow: 1;
			}
		}
		.event_body_time {
			font-size: 10px;
			font-weight: 300;
			position: relative;
			top: 3px;
			color: #fff;
		}
	}
	&.event--past {
		opacity: 0.5 !important;
	}
	&.event--selected {
		box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
		opacity: 1 !important;
		z-index: 50 !important;
	}
	&.event--is-duplicate-mode {
		opacity: 0.2 !important;
	}
	&.event--is-a-duplicate {
		opacity: 1 !important;
	}
	&.event--not-selected {
		opacity: 0.7;
	}
	&.event--tbc {
		border: 2px solid $error-dark;
	}
}
.rbc-event-label {
	display: none;
}

.rbc-current-time-indicator {
	display: none !important;
}

.loading_day_progress {
	position: absolute;
	bottom: 0px;
	width: 100%;
	left: 0px;
}

.planning_toolbar_month {
	text-transform: uppercase;
	h1, h2, h3, h4, p {
		color: $grey-main;
	}
}

.toolbar_switch-days {
	width: 100%;
}

.toolbar_switch-day {
	top: 5px;
	border: 0px;
	background: none;
	position: relative;
	width: 30px;
	height: 30px;
	border-radius: 50%;
	background-color: $primary-contrast;
	outline: none;
	transition: background 0.2s linear;
	will-change: background;
	margin-left: 3px;
	margin-right: 3px;
	.toolbar_switch-day_number {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		font-family: 'Roboto';
		font-weight: 500;
		font-size: 14px;
		color: $grey-dark;
		transition: color 0.2s linear;
		will-change: color;
	}
	.toolbar_switch-day_label {
		position: absolute;
		top: 0px;
		left: 50%;
		transform: translate(-50%, -90%);
		font-family: 'Roboto';
		font-weight: 300;
		font-size: 10px;
		color: $grey-dark;
	}
	&.toolbar_switch-day--is-today {
		background-color: $primary-main;
		.toolbar_switch-day_number {
			color: $primary-contrast;
		}
	}
}

.planning_calendar_fab {
	position: fixed;
	bottom: 10px;
	right: 10px;
	z-index: 50;
}

.floating-information-message {
	position: fixed;
	bottom: 10px;
	right: 80px;
	padding: 20px;
	background-color: $info-main;
	color: $info-contrast;
}

.duplicate-event {
	padding: 15px;
	margin-bottom: 5px;
}

.drawer-duplicate {
	& > div:last-child {
		max-width: 90%;
	}
}

.duplicated-event_label {
	display: flex;
	align-items: center;
	.label {
		flex-grow: 1;
	}
	.icon {
		line-height: 0;
	}
}

/* Small desktop devices */
@media screen and (max-width: 1279px) {

}

/* Tablette Paysage */
@media screen and (max-width: 1024px) {

}

/* Tablette portrait */
@media screen and (max-width: 768px) {

}

/* Mobile */
@media screen and (max-width: 640px) {
	.header_day-week .header_day_summary {
		right: 7px;
		bottom: 0px;
	}
	.planning_calendar {
		padding-left: 10px;
		padding-right: 10px;
	}
	.event_popover_content {
		max-width: 100%;
		min-width: 100%;
	}
}
