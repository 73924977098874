.website--palette--cream {
    color: #596e79;
    background-color: #f0ece2;

    h1, h2, h3, h4, h5, h6 {
        color: #596e79;
    }
    .website_header {
        background-color: #596e79;
    }

    .website_topbar {
        background-color: #c7b198;
        h1, h2, h3, h4, h5, h6 {
            color: #fff;
        }
    }
    .card {
        .card_title {
            .ico {
                color: #c7b198;
            }
        }
    }

    .separator_line {
        border-color: #c7b198;
    }

    a {
    	color: #596e79;
    	&.secondary {
    		color: #596e79;
    	}
    }
}
