.website--palette--pink {
    color: #7d1494;
    background-color: #faedfd;

    h1, h2, h3, h4, h5, h6 {
        color: #7d1494;
    }
    .website_header {
        background-color: #7d1494;
    }

    .website_topbar {
        background-color: #e7a4f5;
        h1, h2, h3, h4, h5, h6 {
            color: #fff;
        }
    }
    .card {
        .card_title {
            .ico {
                color: #d4abdc;
            }
        }
    }

    .separator_line {
        border-color: #d4abdc;
    }

    a {
    	color: #7d1494;
    	&.secondary {
    		color: #7d1494;
    	}
    }
}
