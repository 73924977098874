.website--palette--lavender {
    color: #015792;
    background-color: #f4faff;

    h1, h2, h3, h4, h5, h6 {
        color: #015792;
    }
    .website_header {
        background-color: #b2caff;
    }

    .website_topbar {
        background-color: #e2edfc;
    }
    .card {
        .card_title {
            .ico {
                svg {
                    fill: #b2caff;
                }
            }
        }
    }

    .separator_line {
        border-color: #e2edfc;
    }

    a {
    	color: #015792;
    	&.secondary {
    		color: #b2caff;
    	}
    }
}
