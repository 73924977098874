.website--palette--blue {
    color: #015792;
    background-color: #edf9fc;

    h1, h2, h3, h4, h5, h6 {
        color: #015792;
    }
    .website_header {
        background-color: #53cde2;
    }

    .website_topbar {
        background-color: #d0f4fa;
    }
    .card {
        .card_title {
            .ico {
                svg {
                    fill: #53cde2;
                }
            }
        }
    }

    .separator_line {
        border-color: #d0f4fa;
    }

    a {
    	color: #015792;
    	&.secondary {
    		color: #53cde2;
    	}
    }
}
