@import '_vars.scss';

.account {
	display: flex;
	align-items: flex-start;
	&>div {
		width: 50%;
		padding-right: 10px;
		padding-left: 10px;
	}
	.account_widget {
		margin-bottom: 20px;
		width: 100%;
	}
	.widget_title {
		display: flex;
		align-items: center;
		justify-content: center;
		.logo {
			height: 33px;
			margin-left: 8px;
			img {
				height: 100%;
				width: auto;
			}
		}
	}
	.sprel_contact {
		margin-top: 30px;
		text-align: left;
		div {
			margin-bottom: 5px;
		}
		.name {
			font-size: 26px;
		}
		.value {
			font-weight: bold;
			a {}
		}
	}
	.account_plans {
		display: flex;
		margin-top: 30px;
		.account_plan {
			outline: none;
			border: none;
			cursor: pointer;
			margin-right: 10px;
			margin-bottom: 10px;
			padding: 20px;
			flex-grow: 1;
			background-color: $primary-contrast;
			transition: background-color 0.1s linear;
			will-change: background-color;
			&:last-child {
				margin-right: 0px;
			}
			&.plan--active {
				background-color: $primary-main;
				h1, h1, h4, h6, span, p {
					color: $primary-contrast;
				}
				cursor: default;
			}
			.ico {
				margin-top: 20px;
				svg {
					fill: $primary-contrast
				}
			}
			&:hover:not(.plan--active) {
				background-color: $primary-contrast-high;
			}
		}
	}
}

.invoice_upcoming {
	background-color: $primary-contrast;
	border-bottom: 1px solid $grey-light;
	.invoice_upcoming_details {
		text-align: left;
		align-items: center;
	}
	.invoice_upcoming_label {
		padding: 10px;
	}
	.invoice_upcoming_price {
		padding: 20px;
		background-color: $primary-main;
		h1, h2, h4, span, p {
			color: $primary-contrast;
		}
	}
}

.invoice_item {
	&:nth-child(odd) {
		background-color: $primary-contrast;
	}
	.invoice_date {
		padding-left: 10px;
		color: $grey-main;
	}
	.invoice_ref {
		text-align: left;
		padding-left: 20px;
		padding-right: 20px;
		font-weight: bold;
	}
	.invoice_price{
		padding-left: 20px;
		padding-right: 20px;
		font-weight: bold;
		color: $primary-dark;
	}
	.invoice_actions {
		width: 100px;
		display: flex;
		justify-content: flex-end;
	}
	&.invoice--unpaid {
		background-color: $error-contrast;
	}
}

/* Small desktop devices */
@media screen and (max-width: 1279px) {

}

/* Tablette Paysage */
@media screen and (max-width: 1024px) {
	.account {
		flex-direction: column;
		& > div {
			width: 100%;
		}
	}
}

/* Tablette portrait */
@media screen and (max-width: 768px) {
}

/* Mobile */
@media screen and (max-width: 640px) {
	.account {
		flex-direction: column-reverse;
		.account_plans {
			flex-direction: column;
			.account_plan {
				margin-right: 0px
			}
		}
		.widget_title {
			.logo {
				display: none;
			}
		}
	}
	.invoice_item {
		flex-wrap: wrap;
		.invoice_date {
			padding-top: 5px;
			text-align: left;
			width: 100%;
		}
		.invoice_ref {
			padding-left: 10px;
			padding-top: 5px;
			flex-grow: 1;
		}
		.invoice_actions {
			width: 100%;
			text-align: right;
			justify-content: flex-end;
		}
	}
	.invoice_upcoming {
		.invoice_upcoming_details {
			flex-direction: column-reverse;
			.invoice_upcoming_price {
				width: 100%;
				text-align: center;
			}
			.invoice_upcoming_label {
				h6 {
					text-align: center;
				}
			}
		}
	}
}
