@import '_vars.scss';

.logs {
    padding: 20px;
    .section_title {
        display: block;
        margin-bottom: 15px;
    }
    .logs-filters {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 20px;
        &>* {
            margin-right: 10px;
        }
    }
    .log-item {
        svg {
            &.blue {
                color: $primary-main;
            }
            &.green {
                color: $success-dark;
            }
            &.red {
                color: $error-dark;
            }
        }
    }
}

/* Tablette portrait */
@media screen and (max-width: 768px) {
	.logs {
		.logs-filters {
			flex-wrap: wrap;
		}
	}
}
