@import '_vars.scss';

.schedules-container {
	&>div:nth-child(odd) {
		background-color: $primary-contrast;
	}
	&.schedules-container--margin {
		&>div {
			margin-top: 5px;
			margin-bottom: 5px;
		}
	}
}

.planning-configuration-container {
	padding-bottom: 20px;
}

.color-selector-items {
	width: 300px;
	justify-content: space-between;
}

button.color-selector-item {
	width: calc((100% - 20px) / 3);
	min-width: 0px;
	margin-top: 5px;
	margin-bottom: 5px;
	color: #fff;
}

.appointment-types-topbar {
	display: flex;
	justify-content: flex-end;
	padding-bottom: 20px;
	border-bottom: 1px solid #ddd;
	button {
		margin-left: 5px;
	}
}

.appointment-types-container {
	justify-content: flex-start;
	align-items: flex-start;
}

.appointment-type-item {
	width: calc((100% - 60px) / 3);
	margin: 10px;
}

button.button-expand {
	transition: transform 0.2s ease-out;
	&.button-expand--expanded {
		transform: rotate(180deg);
	}
}

.form-row {
	display: flex;
	align-items: center;
	margin-top: 15px;
	margin-bottom: 15px;
	&>div {
		width: 50%;
		padding-left: 15px;
		padding-right: 15px;
	}
	&>div:first-child {
		text-align: right;
		&>span {
			font-weight: normal;
		}
	}
}

/* Small desktop devices */
@media screen and (max-width: 1279px) {

}

/* Tablette Paysage */
@media screen and (max-width: 1024px) {

}

/* Tablette portrait */
@media screen and (max-width: 768px) {
	.appointment-type-item {
		width: calc((100% - 20px) / 2);
	}
}

/* Mobile */
@media screen and (max-width: 640px) {
	.appointment-type-item {
		width: 100%;
	}
	.form-row {
		flex-wrap: wrap;
		margin-bottom: 30px;
		&>div {
			width: 100%;
			padding-left: 0px;
			padding-right: 0px;
		}
		&>div:first-child {
			text-align: left;
		}
		&.form-row--switch {
			flex-wrap: nowrap;
			&>div {
				width: auto;
			}
			&>div:first-child {
				flex-grow: 1;
				padding-right: 10px;
			}
		}
	}
}
