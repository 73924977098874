.website--palette--sinbad {
    color: #364e68;
    background-color: #ebf0f6;

    h1, h2, h3, h4, h5, h6 {
        color: #364e68;
    }
    .website_header {
        background-color: #364e68;
    }

    .website_topbar {
        background-color: #98ccd3;
        h1, h2, h3, h4, h5, h6 {
            color: #fff;
        }
    }
    .card {
        .card_title {
            .ico {
                color: #98ccd3;
            }
        }
    }

    .separator_line {
        border-color: #98ccd3;
    }

    a {
    	color: #364e68;
    	&.secondary {
    		color: #364e68;
    	}
    }
}
