.website--palette--rose {
    color: #490a0a;
    background-color: #fff8f2;

    h1, h2, h3, h4, h5, h6 {
        color: #490a0a;
    }
    .website_header {
        background-color: #ea8585;
    }

    .website_topbar {
        background-color: #f6caac;
    }
    .card {
        .card_title {
            .ico {
                svg {
                    fill: #ea8585;
                }
            }
        }
    }

    .separator_line {
        border-color: #f6caac;
    }

    a {
    	color: #490a0a;
    	&.secondary {
    		color: #ea8585;
    	}
    }
}
