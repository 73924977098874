.website--palette--comet {
    color: #69779b;
    background-color: #e7f0f0;

    h1, h2, h3, h4, h5, h6 {
        color: #69779b;
    }
    .website_header {
        background-color: #586380;
    }

    .website_topbar {
        background-color: #9692af;
        h1, h2, h3, h4, h5, h6 {
            color: #fff;
        }
    }
    .card {
        .card_title {
            .ico {
                color: #9692af;
            }
        }
    }

    .separator_line {
        border-color: #9692af;
    }

    a {
    	color: #69779b;
    	&.secondary {
    		color: #69779b;
    	}
    }
}
