.website--palette--duckgreen {
    color: #1d5464;
    background-color: #f2fcfd;

    h1, h2, h3, h4, h5, h6 {
        color: #1d5464;
    }
    .website_header {
        background-color: #1d5464;
    }

    .website_topbar {
        background-color: #207e82;
        h1, h2, h3, h4, h5, h6 {
            color: #fff;
        }
    }
    .card {
        .card_title {
            .ico {
                color: #d8d860;
            }
        }
    }

    .separator_line {
        border-color: #207e82;
    }

    a {
    	color: #1d5464;
    	&.secondary {
    		color: #1d5464;
    	}
    }
}
