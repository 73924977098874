$primary-light: #67daff;
$primary-main: #015792;
$primary-dark: #007ac1;
$primary-contrast: #e3f2fd;
$primary-contrast-high: #90caf9;

$secondary-light: #ffad42;
$secondary-main: #f57c00;
$secondary-dark: #bb4d00;
$secondary-contrast: #fff3e0;
$secondary-contrast-high: #ffc366;

$background-color: #fafafa;

$error-light: #ff7961;
$error-main: #f44336;
$error-dark: #ba000d;
$error-contrast: #ffebee;
$error-contrast-high: #ef9a9a;

$success-light: #76d275;
$success-main: #43a047;
$success-dark: #00701a;
$success-contrast: #e8f5e9;
$success-contrast-high: #a5d6a7;

$info-light: #67daff;
$info-main: #03a9f4;
$info-dark: #007ac1;
$info-contrast: #e1f5fe;

$warning-light: #ff8a50;
$warning-main: #ff5722;
$warning-dark: #c41c00;
$warning-contrast: #fbe9e7;

$grey-light: #cfcfcf;
$grey-main: #9e9e9e;
$grey-dark: #707070;
$grey-contrast: #f5f5f5;
$grey-contrast-high: #bdbdbd;
