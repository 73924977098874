@import '_vars.scss';

.visio {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1100;
    background-color: #000;
    &:hover {
        .visio_toggle-minify {
            opacity: 1;
        }
    }

    &.visio--minified {
        top: initial;
        left: initial;
        bottom: 10px;
        right: 10px;
        width: 420px;
        height: 236px;
        .visio_stream {
            &--remote {
                width: 320px;
                height: 240px;
            }
            &--local {
                width: 80px;
                height: 60px;
                position: absolute;
                right: 5px;
                bottom: 5px;
                z-index: 5;
            }
        }
        .visio_controls {
            display: none;
        }
        .visio_waiting-for {
            font-size: 12px;
            text-align: left;
            padding-left: 10px;
        }
        .visio_local-controls {
            right: 0;
            bottom: 5px;
            justify-content: flex-start;
            padding-left: 5px;
        }
    }
}

.visio_local-controls {
    width: 320px;
    position: absolute;
    right: 20px;
    bottom: 20px;
    z-index: 6;
    display: flex;
    justify-content: center;
    .visio_local-control {
        background-color: rgba(255,255,255,0.1);
        margin-left: 2px;
        margin-right: 2px;
        &--off {
            color: #f44336 !important
        }
    }
}

.visio_toggle-minify {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    button {
        color: #fff !important;
    }
    opacity: 0;
}

.visio_content {
    position:  relative;
    flex-grow: 1;
    background-color: black;
    align-items: center;
    justify-content: center;
    display: flex;
    height: 100%;
}
.visio_videos {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-evenly;
    flex-grow: 1;
    height: 100%;
}

.visio_loading {
    position: absolute;
    bottom: 20px;
    left: calc(50% - 60px);
    width: 120px;
}

.visio_stream {
    line-height: 0px;
    position: relative;
    border: 1px solid rgba(255,255,255,0.1);
    video {
        width: 100%;
        height: 100%;
    }
    &--remote {
        width: 960px;
        height: 720px;
    }
    &--local {
        width: 320px;
        height: 240px;
        position: absolute;
        right: 20px;
        bottom: 20px;
        z-index: 5;
    }
}

.visio_waiting-for {
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
    padding: 20px;
    text-align: center;
    color: white;
    font-size: 18px;
    background-color: rgba(255,255,255,0.1);
    span {
        font-weight: bold;
    }
}

.visio_waiting-loader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.visio_error-perm {
    width: 400px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
} 

.visio_controls {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    .visio_control {
        margin-left: 8px;
        margin-right: 8px;
    }
}

.visio_control--end {
    background-color: #f44336 !important;
    color: #fff !important;
}

.visio_files-sender_dropzone {
    position: relative;
    min-height: 150px;
    width: 100%;
    border: 5px dashed lightgrey;
    outline: none;
    &.active {
        border-color: $success-main;
    }
    &.reject {
        border-color: $error-main;
    }
}
.visio_files-sender-message {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: rgba(255,255,255,0.8);
}
.visio_files-files_list {
    padding: 20px;
    display: flex;
    flex-direction: column;
}

.visio_files-files_list_item {
    display: flex;
    align-items: center;
    padding: 5px;
    .name {
        flex-grow: 1;
    }

    &:nth-child(odd) {
        background-color: lightgray;
    }
}

.visio_delay_content {
    display: flex !important;
    align-items: center;
}

.visio_delay_input {
    width: 75px;
    margin-left: 5px !important;
    margin-right: 5px !important;
}
.visio_delay_preview {
    margin-top: 15px;
    border-left: 5px solid lightgrey;
    padding-left: 10px;
    font-style: italic;
}

.visio_files-received {
    position: absolute;
    left: 5px;
    bottom: 0px;
    padding: 10px;
    border-radius: 5px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    background-color: rgba(255,255,255,0.8);
    width: 350px;
    padding-bottom: 20px;
    z-index: 5;
}
.visio_files-received_container {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.visio_files-received_file {
    padding: 8px !important;
    margin-bottom: 4px !important;
    justify-content: flex-start !important;
    &:last-child {
        margin-bottom: 0px !important;
    }
}
.visio_files-received_file-icon {
    margin-right: 4px;
}