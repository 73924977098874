@import '_vars.scss';

.inbox-list-emails {
	width: 450px;
	min-width: 450px;
	border-right: 1px solid rgba(0, 0, 0, 0.12);
	height: calc(100vh - 230px);
	overflow-y: auto;
	button.inbox-message-button {
		width: 100%;
		padding: 10px;
		display: block;
		text-align: left;
		justify-content: flex-start;
		text-transform: none;
		.inbox-message-button-title {
			color: inherit;
		}
		&.button--active {
			background-color: $primary-contrast;
		}
	}
}

.inbox-message-details {
	padding: 20px;
	flex-grow: 1;
	.message-body {
			background-color: #fff;
	}
}

.inbox-placeholder {
	text-align: center;
	padding-top: 50px;
	color: $grey-light;
	.ico {
		font-size: 150px;
		fill: $grey-light;
	}
}

.inbox-reply-drawer {
	&>div:last-child {
		width: 700px;
		margin-left: calc(100% - 700px - 40px;)
	}
}

.dropzone {
	outline: none;
	position: relative;
	.dropzone-placeholder {
		pointer-events: none;
		position: absolute;
		left: 0px;
		top: 0px;
		width: 100%;
		height: 100%;
		background-color: rgba(255,255,255,0.8);
		z-index: 400;
		.content-inner {
			position: absolute;
			top: 10px;
			left: 10px;
			width: calc(100% - 20px);
			height: calc(100% - 20px);
			border: 5px dashed $primary-dark;
		}
	}
	&.dropzone--reject {
		.dropzone-placeholder {
			.content-inner {
				border-color: $error-main;
			}
		}
	}
	&.dropzone--accept {
		.dropzone-placeholder {
			.content-inner {
				border-color: $success-main;
			}
		}
	}
	.dropzone-drophere {
		width: 100%;
		text-align: center;
		position: relative;
		top: 50%;
		transform: translateY(-50%);
	}
}

.inbox-mobile-title {
	display: flex;
	align-items: center;
}

/* Small desktop devices */
@media screen and (max-width: 1279px) {

}

/* Tablette Paysage */
@media screen and (max-width: 1024px) {

}

/* Tablette portrait */
@media screen and (max-width: 768px) {
	.inbox-list-emails {
		width: 100%;
		min-width: 0px;
		border-right: none;
		height: auto;
	}

	.inbox-reply-drawer > div:last-child {
		width: calc(100% - 40px);
		margin-left: 20px;
	}
}

/* Mobile */
@media screen and (max-width: 640px) {
	.message-toolbar {
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		text-align: left;
		&>div {
			width: 100%;
		}
		.message-title {
			padding-top: 10px;
		}
		.message-action-bar {
			text-align: right;
		}
	}
}
