@import '_vars.scss';

/* Small desktop devices */
@media screen and (max-width: 1279px) {
	#resize-size-checker {
		width: 5px;
	}
	.column {
		width: 100%;
	}
}

/* Tablette Paysage */
@media screen and (max-width: 1024px) {
	#resize-size-checker {
		width: 4px;
	}
}

/* Tablette portrait */
@media screen and (max-width: 768px) {
	#resize-size-checker {
		width: 3px;
	}
	.family-members {
		&>div {
			width: calc((100% - 40px) / 2);
		}
	}
}

/* Mobile */
@media screen and (max-width: 640px) {
	#resize-size-checker {
		width: 2px;
	}
	body, #root, .root {
		overflow-x: hidden;
	}
	.box {
		width: calc(100% - 40px);
		margin-left: auto;
		margin-right: auto;
	}

	.menu-left {
		.menu-left_container {
			width: 300px;
			.menu-left_header {
				padding-top: 100px;
				h2 {
					font-size: 16px;
					width: 260px;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
				}
			}
		}
	}

	.playground {
		padding-left: 0px;
	}

	.logo-login {
		img {
			max-width: 50vw;
		}
		.logo-login-pro {
			right: 0px;
			font-size: 12px;
			transform: translate(100%, 10%);
		}
	}

	footer {
		flex-direction: column;
	}

	.warning-no-internet {
		left: 0px;
		width: 100%;
		padding-top: 5px;
		padding-bottom: 5px;
		text-align: center;
		border-top-left-radius: 0px;
		border-top-right-radius: 0px;
	}

	.warning-not-certified {
		left: 0px;
		width: 100%;
		padding-top: 5px;
		padding-bottom: 5px;
		text-align: center;
		border-top-left-radius: 0px;
		border-top-right-radius: 0px;
		transform: none;
	}

	.menu-left-mobile {
		.menu-left-logo {
			display: inline-block;
			text-align: center;
			background-color: $primary-main;
			background-size: 50%;
			background-repeat: no-repeat;
			background-position: center;
			height: 120px;
		}
	}
}
