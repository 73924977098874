@import '_vars.scss';

.dashboard_widgets {
	margin-top: 30px;
	display: flex;
	flex-wrap: wrap;
}

.dashboard_widget {
	margin: 10px;
	.dashboard_widget_caption {
		display: block;
		margin-top: 8px;
	}
}

.widget_title {
	text-transform: uppercase;
	font-weight: 300;
}


/* Small desktop devices */
@media screen and (max-width: 1279px) {

}

/* Tablette Paysage */
@media screen and (max-width: 1024px) {

}

/* Tablette portrait */
@media screen and (max-width: 768px) {

}

/* Mobile */
@media screen and (max-width: 640px) {
	.dashboard_widgets {
		flex-direction: column;
	}
}
