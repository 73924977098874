@import '_vars.scss';

.payments_unregistered_splitter {
    margin-top: 30px;
    display: flex;
    align-items: flex-start;
    & > div {
        flex-grow: 1;
    }
}
.payments_form {
    min-width: 48%;
}
.payments_dob_label {
    margin-top: 15px;
}
.payments_dob {
    display: flex;
}

.payments_city {
    display: flex;
    .payments_city_postal {
        width: 150px !important;
        margin-right: 10px;
    }
}

.payments_iban_confirm {
    padding-left: 20px;
    min-width: 50%;
}

.payments_iban-label {
    padding-top: 16px;
    font-weight: bold;
    padding-bottom: 10px;
    &.error {
        color: $error-main !important;
    }
}
.payments_submit-container {
    margin-top: 25px;
}
.payments_acceptances {
    margin-top: 32px;
    .MuiFormControlLabel-root {
        align-items: flex-start !important;
        & > span:first-child {
            top: 5px !important;
        }
    }
    .error {
        color: $error-main !important;
        .MuiCheckbox-root {
            color: $error-main !important;
        }
    }
}

.payment_backdrop-loader {
    z-index: 5000 !important;
    & > div {
        width: 100px;
    }
}

.payments_documents_zones {
    margin-top: 40px;
    display: flex;
}

.payments_document {
    margin-left: 10px;
    margin-right: 10px;
    flex-grow: 1;
    display: flex;
    min-height: 150px;
    align-items: center;
    justify-content: center;
    border: 5px dashed lightgrey;
    min-width: calc((100% - 60px) / 3);
    padding: 20px;
    text-align: center;
    min-height: 270px;
    &--accept {
        border-color: $success-main;
    }
    &--reject {
        border-color: $error-main;
    }
}
.payments_documents_submit {
    margin-top: 30px;
    display: flex;
    justify-content: center;
}

.payments_create {
    input {
        text-align: right;
    }

}

.payments-manage_balances {
    margin-bottom: 20px;
    display: flex;
    @media screen and (max-width: 640px) {
        flex-direction: column;
        margin-top: 20px;
    }
    .payments-manage_balance {
        margin-right: 20px;
        display: flex;
        align-items: stretch;
        color: #fff;
        @media screen and (max-width: 640px) {
            margin-right: 0px;
            margin-bottom: 10px;
        }
        .label {
            padding-left: 10px;
            padding-right: 10px;
            display: flex;
            align-items: center;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            @media screen and (max-width: 640px) {
                padding-top: 5px;
                padding-bottom: 5px;
            }
        }
        .amount {
            padding-top: 10px;
            padding-bottom: 10px;
            padding-right: 15px;
            padding-left: 15px;
            font-weight: bold;
            font-size: 16px;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            @media screen and (max-width: 640px) {
                min-width: 75px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
            }
        }
        &.balance--transit {
            .label {
                background-color: $success-main;
            }
            .amount {
                background-color: $success-dark;
            }
        }
        &.balance--available {
            .label {
                background-color: $info-main;
            }
            .amount {
                background-color: $info-dark;
            }
        }
        &.balance--pending {
            .label {
                background-color: $grey-main;
            }
            .amount {
                background-color: $grey-dark;
            }
        }
    }
}

.payments-manage_columns {
    display: flex;
    align-items: flex-start;
    @media screen and (max-width: 640px) {
        flex-direction: column;
    }
    .payments-manage_unpaid {
        margin-right: 20px;
        min-width: 50%;
        @media screen and (max-width: 640px) {
            margin-right: 0px;
            width: 100%;
            margin-bottom: 40px;
        }
    }
}

.payment-item {
    border-bottom: 1px solid $grey-light;
    font-size: 12px;
    display: flex;
    align-items: center;
    &.payments-header {
        background-color: $grey-dark !important;
        color: #fff;
        .pi--cell {
            display: flex;
            font-size: 10px;
            font-weight: bold;
        }
    }
    .pi--cell {
        padding: 5px;
    }
    .pi--date {
        width: 105px;
        font-size: 10px;
        @media screen and (max-width: 640px) {
            display: none !important;
        }
    }
    .pi--state {
        width: 170px;
        @media screen and (max-width: 640px) {
            width: 60px;
        }
        .state {
            display: flex;
            align-items: stretch;
            .label {
                font-weight: bold;
                padding: 5px;
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
                @media screen and (max-width: 640px) {
                    border-radius: 5px;
                }
            }
            .date {
                display: flex;
                align-items: center;
                font-size: 10px;
                padding: 5px;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
                @media screen and (max-width: 640px) {
                    display: none;
                }
            }
            &--unpaid {
                .label {
                    background-color: $error-main;
                    color: $error-contrast;
                    border-top-right-radius: 5px;
                    border-bottom-right-radius: 5px;
                }
                .date {
                    background-color: $error-dark;
                    color: $error-contrast;
                }
            }
            &--paid {
                .label {
                    background-color: $success-main;
                    color: $success-contrast;
                }
                .date {
                    background-color: $success-dark;
                    color: $success-contrast;
                }
            }
            &--canceled {
                .label {
                    background-color: $secondary-main;
                    color: $secondary-contrast;
                }
                .date {
                    background-color: $secondary-dark;
                    color: $secondary-contrast;
                }
            }
        }
    }
    .pi--who {
        flex-grow: 1;
    }
    .pi--amount {
        width: 85px;
        display: flex;
        justify-content: flex-end;
        @media screen and (max-width: 640px) {
            width: 60px;
        }
        @media screen and (max-width: 640px) {
            &.--fees {
                display: none !important;
            }
        }
        &.--collected {
            font-weight: bold;
            @media screen and (max-width: 640px) {
                display: flex !important;
            }
        }
        &.grow {
            flex-grow: 1;
        }
    }
    .pi--action {
        width: 45px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 5px;
    }
    &:nth-child(odd) {
        background-color: #fff; 
    }
}

.payments-search {
    flex-grow: 1;
    @media screen and (max-width: 640px) {
        width: 100%;
    }
   .payments-search--select {
       width: 100%;
   }
   .payments-search--results {
       margin-top: 20px;
   }
}

.payments-rib {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    @media screen and (max-width: 640px) {
        display: none !important;
    }
    svg {
        margin-right: 5px;
    }
}

.payments-add-rib {
    padding: 20px;
    border: 1px solid lightgrey;
    border-radius: 5px;
}