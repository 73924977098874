@import '_vars.scss';


.users-container {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: flex-start;
}

.user-card {
	width: calc((100% - 40px) / 3);
	margin-bottom: 20px;
	&.user-card--blocked {
		background-color: #ef9a9a;
	}
	&.full-width {
		width: 100%;
	}
}

.users-members-container {
	.user-card {
		width: 100%;
	}
}

.user-details-payments {
	height: 500px;
	overflow-y: auto;
}

.user-details-title {
	h2 {
		display: flex;
		align-items: center;
		width: 100%;
		padding-right: 58px;
	}
	.float-menu-more {
		position: absolute;
		right: 5px;
		top: 5px;
	}
}

.user-details-pane {
	padding: 15px;
}

.users_has-more {
	width: 100%;
	text-align: right;
	&>span {
		font-weight: bold;
	}
}

.user-no-user-found {
	display: flex;
	flex-direction: column;
	button {
		margin-top: 20px;
		align-self: center;
	}
}

/* Small desktop devices */
@media screen and (max-width: 1279px) {

}

/* Tablette Paysage */
@media screen and (max-width: 1024px) {

}

/* Tablette portrait */
@media screen and (max-width: 768px) {
	.user-card {
		width: calc((100% - 20px) / 2);
	}
	.users-members-container {
		.user-card {
			width: 100%;
		}
	}
}

/* Mobile */
@media screen and (max-width: 640px) {
	.user-card {
		width: 100%;
	}

	.users {
		.section_title {
			flex-direction: column;
			h4 {
				width: 100%;
				margin-bottom: 10px;
			}
		}
	}
}
