.root--umJbmZBVRJb1X38EfoEa0A5CoRZICG {
	.event--blocked {
		background-color: #b7b7b7 !important;
	}
	.slot--is-bookable:not(.slot--is-past):not(.slot--is-closed-by-day) {
		background-color: #ffcaca !important;
	}
	.slot--is-closed-by-day {
		background-color: #b7b7b7 !important
	}
}