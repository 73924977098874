@import '_vars.scss';

body {
	font-family: 'Roboto';
	font-weight: normal;
	width: 100%;
}

#root, .root {
	position: relative;
}

footer {
	margin-top: 40px;
	padding-bottom: 40px;
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	align-items: center;
	&> * {
		margin-right: 20px !important;
	}
}

.section_title {
	h4 {
		padding-left: 10px;
	}
}

/* ATOMIC */
.w20 {
	width: 20px;
	min-width: 20px;
}
.w150 {
	width: 150px;
	min-width: 150px;
}
.fullh {
	height: 100%;
}
.fg1 {
	flex-grow: 1;
}
.dblock {
	display: block;
}
.w100 {
	width: 100%;
}

.w25 {
	width: 25%;
}

.w33 {
	width: calc(100% / 3);
}

.w50 {
	width: 50%;
}

.tar {
	text-align: right;
	justify-content: flex-end;
}

.tal {
	text-align: left;
	justify-content: flex-start;
}

.tac {
	text-align: center;
	justify-content: center;
}

.jc-c {
	justify-content: center;
}

.large-margin {
	margin-top: 20px !important;
	margin-bottom: 20px !important;
}

.prel {
	position: relative;
}

.mt20 {
	margin-top: 20px !important;
}

.mt40 {
	margin-top: 40px !important;
}

.mt60 {
	margin-top: 60px !important;
}

.mt80 {
	margin-top: 80px !important;
}

.mt10 {
	margin-top: 10px !important;
}

.mb10 {
	margin-bottom: 10px !important;
}

.mb20 {
	margin-bottom: 20px !important;
}

.mb40 {
	margin-bottom: 40px !important;
}

.mr5 {
	margin-right: 5px !important;
}

.mr10 {
	margin-right: 10px !important;
}

.mr20 {
	margin-right: 20px !important;
}

.ml5 {
	margin-left: 5px !important;
}

.ml10 {
	margin-left: 10px !important;
}

.ml20 {
	margin-left: 20px !important;
}

.padl0 {
	padding-left: 0px !important;
}

.padt10 {
	padding-top:10px !important;
}

.padl10 {
	padding-left:10px !important;
}

.padl20 {
	padding-left: 20px !important;
}

.padr20 {
	padding-right: 20px !important;
}

.padr10 {
	padding-right: 10px !important;
}

.padb20 {
	padding-bottom: 20px !important;
}

.padt20 {
	padding-top: 20px !important;
}

.pad40 {
	padding: 40px !important;
}

.pad20 {
	padding: 20px !important;
}

.pad10 {
	padding: 10px !important;
}

.nowrap {
	white-space: nowrap;
}

.fw-w {
	flex-wrap: wrap;
}

.d-ib {
	display: inline-block;
}

.flex {
	display: flex !important;
}

.flex1 {
	flex: 1;
}

.fg1 {
	flex-grow: 1 !important;
}

.flex-c {
	flex-direction: column;
}

.aic {
	align-items: center;
}

.ais {
	align-items: stretch;
}

.ait {
	align-items: flex-start;
}

.no-shadow {
	box-shadow: none !important;
}

.column {
	width: 1200px;
	padding-left: 20px;
	padding-right: 20px;
	margin-left: auto;
	margin-right: auto;
}

/* Design */
.box {
	position: relative;
	width: 100%;
	max-width: 500px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 50px;
	margin-top: 50px;
	&.box--large {
		max-width: 100%;
	}
	.box_header {
		position: relative;
		background-color: $primary-dark;
		padding-top: 80px;
		padding-left: 20px;
		padding-right: 20px;
		padding-bottom: 10px;
		&.box_header--primary {
			background-color: $primary-main;
		}
		&.box_header--secondary {
			background-color: $secondary-main;
		}
		&.box_header--condensed {
			padding-top: 40px;
		}
		.floating-logo {
			position: absolute;
			top: 0px;
			right: 0px;
			width: 60px;
			height: 60px;
			transform: translate(30%, -30%);
			padding: 10px;
			background-color: $background-color;
			border-radius: 50%;
			border: none;
			outline: none;
			img {
				width: 100%;
			}
		}
	}
	h2 {
		color:$primary-contrast;
	}
}

a {
	text-decoration: none;
	color: $primary-main;
	&.secondary {
		color: $secondary-main;
	}
	&:hover {
		text-decoration: underline;
	}
}

.button-loader {
	margin-left: 15px;
	position: relative;
	top: 2px;
}

/* MAIN LOADER */
.app_splash-screen {
	display: flex;
	flex-direction: column;
	height: 100vh;
}

.app_splash_version {
	flex-grow: 1;
	display: flex;
	align-items: flex-end;
	justify-content: center;
	padding-bottom: 5px;
	color: $primary-main;
	font-size: 10px;
	font-weight: bold;
}

.app_main-loader {
	display: flex;
	align-items: flex-end;
	justify-content: center;
	
}

.app-loader {
	width: 100px;
	margin-left: auto;
	margin-right: auto;
}

.logo-login-pro {
	font-weight: bold;
	font-size: 13px;
	color: #fff;
	background-color: #53cde2;
	padding: 5px;
	border-radius: 4px;
	margin-left: 10px;
	display: inline;
}

/* Header */
.app-header {
	h1 {
		margin-left: 20px;
		margin: 0px !important;
		padding: 0px !important;
		font-size: 0px !important;
	}
	.app-header_logo {
		height: 35px;
		width: auto;
	}
}

.playground {
	padding-left: 55px;
}

/* Error messages */
.message {
	position: relative;
	padding: 25px;
	color: #fff;
	h1, h2, h3, h4, h5, h6 {
		color: #fff !important;
	}
	p {
		margin: 0px;
		margin-bottom: 20px;
		&:last-child {
			margin-bottom: 0px;
		}
	}
	p, div, a {
		color: #fff;
	}
	a {
		font-weight: bold;
		text-decoration: underline;
	}
	&.message--none {
		color: inherit;
		div, p, a {
			color: inherit;
		}
	}
	&.message--error {
		background-color: #ff0826;
	}
	&.message--success {
		background-color: #43a047;
	}
	&.message--information {
		background-color: #53cde2;
	}
	&.message--warning {
		background-color: #ff7d63;
	}
}

/* OUTLINE */
.no-outline {
	outline: none;
}

/* Chips */
.chip {
	margin-right: 5px;
	color: #fff !important;
	&:focus {
		background-color: inherit !important;
	}
	&:hover{
		background-color: inherit !important;
	}
	&.chip--error {
		background-color: #ff0826 !important;
	}
	&.chip--success {
		background-color: #53e296 !important;
	}
	&.chip--information {
		background-color: #53cde2 !important;
	}
	&.chip--warning {
		background-color: #ff7d63 !important;
	}
	&.chip--inactive {
		opacity: 0.5;
	}
	&.chip--none {
		background-color: #fff !important;
		color: #000 !important;
	}
}

/* CARDS */
// CARDS
.card {
	&.card--shadow {
		box-shadow: 0 1px 3px 0 rgba(140, 140, 140, 0.2), 1px 1px 4px 0 rgba(140, 140, 140, 0.3);
	}
	background-color: #fff;
	height: 100%;
	.card_content {
		padding: 25px;
		&.card_infos {
			font-size: 14px;
		}
	}
	.card_title {
		margin-bottom: 20px;
		display: flex;
		align-items: center;
		width: 100%;
		.ico {
			svg {
				width: 25px;
				height: 25px;
			}
			margin-right: 15px;
		}
		h3 {
			margin: 0px;
			padding: 0px;
			font-size: 30px;
			font-weight: normal;
		}
	}
	.card_actions {
		padding: 7px;
		padding-right: 20px;
		padding-left: 20px;
		padding-bottom: 20px;
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}
}

.menu-left-desktop {
	&>div {
		top: 63px !important;
		height: calc(100vh - 63px) !important;
		z-index: 1000;
		overflow-y: visible;
	}
	.menu-left_content {
		.menu_left_item {
			padding-left: 16px !important;
			padding-right: 16px !important;
			position: relative;
			&.active {
				background: $primary-contrast;
			}
			&>div {
				margin-right: 0px;
			}
		}
		svg {
			margin-right: 0px !important;
		}
	}
	.MuiListItemIcon-root {
		min-width: 0;
	}
}

.menu-left_content {
	.menu_left_item {
		&.active {
			background: $primary-contrast;
		}
	}
}

a.menu-nav-current {
	background-color: $secondary-contrast;
	color: $secondary-dark;
	&:after {
		content: '';
		position: absolute;
		right: 0px;
		top: 0px;
		height: 100%;
		width: 5px;
		background-color: $secondary-main;
	}
}

.menu-left_container {
	a {
		color: $secondary-main;
		&:hover {
			background-color: $secondary-contrast;
		}
	}
}


.avatar-gender {
	&.gender--female {
		background-color: #F06292;
	}
	&.gender--male {
		background-color: #7986CB;
	}
	&.gender--other {
		background-color: #A1887F;
	}
}

.avatar--secondary {
	background-color: $secondary-main !important;
}

.avatar--primary {
	background-color: $primary-main !important;
}

.avatar--small {
	width: 30px !important;
	height: 30px !important;
}

.avatar--big {
	width: 60px !important;
	height: 60px !important;
}

/* ICONS */
.ico--primary {
	fill: $primary-main !important;
}

.ico--grey {
	fill: $grey-main !important;
}

.ico--secondary {
	fill: $secondary-main !important;
}

.ico--inline {
	position: relative;
	top: 5px;
	&.ico--inline--short {
		top: 2px;
	}
}
.ico--stroked {
	fill: transparent !important;
	stroke: #fff;
	stroke-width: 2px;
}

.ico--15 {
	font-size: 15px !important;
}

/* Buttons */
button {
	&.button--success {
		background-color: $success-main;
		color: $success-contrast;
		&:hover {
			background-color: $success-dark;
		}
	}
	&.button--danger {
		background-color: $error-main;
		&:hover {
			background-color: $error-dark;
		}
	}
}

/* FAMILY */
.family-members-container {
	position: relative;
}
.family-members {
	position: relative;
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
	&>div {
		margin-top: 10px;
		margin-bottom: 10px;
		margin-left: 10px;
		margin-right: 10px;
		width: calc((100% - 60px) / 3);
	}
}
.family-member-add {
	position: absolute;
	bottom: -16px;
	right: -10px;

}

.card_header {
	padding: 10px;
	&.card_header--secondary {
		background-color: $secondary-main;
		h1, h2, h3, h4, h5, h6 {
			color: $secondary-contrast;
		}
		h2 {
			font-weight: 400;
			font-size: 20px;
		}
	}
}

/* WARNING */
.warning-no-internet {
	position: fixed;
	bottom: 0px;
	left: 10px;
	padding: 15px;
	background-color: $warning-dark;
	color: $warning-contrast;
	font-size: 14px;
	font-weight: 300;
	border-top-right-radius: 5px;
	border-top-left-radius: 5px;
	z-index: 99999;
	display: flex;
	align-items: center;
	justify-content: center;
	svg {
		position: relative;
	}
	span {
		margin-left: 7px;
	}
}

.warning-not-certified {
	position: fixed;
	bottom: 0px;
	text-align: center;
	left: 50%;
	max-width: 50%;
	transform: translateX(-50%);
	padding: 15px;
	background-color: $warning-dark;
	color: $warning-contrast;
	font-size: 14px;
	font-weight: 300;
	border-top-right-radius: 5px;
	border-top-left-radius: 5px;
	button {
		margin-top: 10px;
	}
}

/* MODALES */
.modale_close {
	position: absolute;
	right: 0px;
	top: 0px;
}

/* Toastify */
.Toastify__toast-container {
	width: 327px !important;
}
.Toastify__toast {
	&.Toastify__toast--success {
		background-color: $success-main !important;
		color: $success-contrast !important;
	}
	&.Toastify__toast--error {
		background-color: $error-main !important;
		color: $error-contrast !important;
	}
	&.Toastify__toast--info {
		background-color: $info-main !important;
		color: $info-contrast !important;
	}
	button {
		color: #fff !important;
		.ico {
			margin-right: 5px;
		}
	}
	.toast-close {
		position: absolute;
		top: 0;
		right: 0;
	}
}

/* RTE */
.rte {
	background: none !important;
	border: none !important;
	&>div:first-child {
		&>div:last-child {
			color: #000;
			background-color: #fff;
		}
	}
	&.minh {
		.public-DraftEditor-content {
			min-height: 150px;
		}
	}
	&.bkg {
		background-color: $grey-contrast !important;
	}
}

/* TOOLTIP */
.tippy-tooltip [x-circle] {
	border-radius: 0;
	height: 100%;
}

.badge--decal-bl {
	&>span {
		top: 0px;
		right: 0px;
	}
}
.badge--nope {
	&>span {
		pointer-events: none;
	}
}


/* RESIZE CHECKER */
#resize-size-checker {
	position: absolute;
	width: 6px;
	height: 0px;
	display: block;
	pointer-events: none;
	opacity: 0;
}

/* Muioverride */
.MuiListItemIcon-root {
	min-width: 0 !important;

}
.MuiListItemText-inset {
	padding-left: 12px !important;
}

.MuiTypography-root.MuiTypography-caption {
	display: block;
}