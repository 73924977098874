@import '_vars.scss';

.appointment-dialog-form {
	padding-top: 15px !important;
	.row {
		margin-bottom: 20px;
	}
	.appointment_type-color {
		margin-left: 10px;
		position: relative;
		top: 1px;
	}
}

.select_appointment {
	.MuiSelect-root {
		display: flex;
		height: 20px;	
	}
}

.appointment_type {
	display: flex;
}

.appointment_type-color {
	width: 15px;
	height: 15px;
	border-radius: 50%;
	display: inline-block;
	margin-right: 10px;
}

.appointment_type-label {
	flex-grow: 1;
}

.appointment_type-ico {
	line-height: 0;
}

.react-autosuggest__container {
	flex-grow: 1;
    position: relative;
    height: 250;
}

.react-autosuggest__suggestions-container--open {
	position: absolute;
    z-index: 5;
    margin-top: 10px;
    left: 0;
    right: 0;
}

.react-autosuggest__suggestion {
	display: block;
}

.react-autosuggest__suggestions-list {
	margin: 0;
    padding: 0;
    list-style-type: none;
}

.name-input_search_result_name {
	top: -6px;
}

.name-input_search_result_age {
	top: -10px;
}

.conficted-event {
	margin-top: 10px;
}
.conflict-event {
	margin-bottom: 10px;
	width: 100%;
}


/* Appointments to confirm */
.appointments-container {
	flex-wrap: wrap;
}

.appointment-card {
	margin: 10px;
	width: calc((100% - 60px) / 3 );

	&.event_popover_content {
		max-width: none;
		min-width: 0px;
		.event_popover_confirm {
			padding-bottom: 0px;
		}
	}

	.event-appointmentType {
		display: flex;
		.label {
			flex-grow: 1;
		}
		.icon {
			line-height: 0;
		}
	}
}

/* Small desktop devices */
@media screen and (max-width: 1279px) {

}

/* Tablette Paysage */
@media screen and (max-width: 1024px) {
	.appointment-card {
		width: calc((100% - 40px) / 2 );
	}
}

/* Tablette portrait */
@media screen and (max-width: 768px) {
}

/* Mobile */
@media screen and (max-width: 640px) {
	.appointment-card {
		margin-left: 0px;
		margin-right: 0px;
		width: 100%;
	}
}
