.website--palette--green {
    color: #015792;
    background-color: #f3f9ed;

    h1, h2, h3, h4, h5, h6 {
        color: #015792;
    }
    .website_header {
        background-color: #3bc69e;
    }

    .website_topbar {
        background-color: #acf6b3;
    }
    .card {
        .card_title {
            .ico {
                svg {
                    fill: #3bc69e;
                }
            }
        }
    }

    .separator_line {
        border-color: #acf6b3;
    }

    a {
    	color: #015792;
    	&.secondary {
    		color: #3bc69e;
    	}
    }
}
